<template>
  <div class="top_title">

    <div class="top_input clearfix">
      <h6>发票代码</h6>
      <el-input style="width:50%;" v-model="info.fpdm" maxlength="12" size="small"></el-input>
    </div>
    <div class="bottom_time clearfix">
      <div class="top_input2 clearfix fl" style="width:30%">
        <h6>发票号码</h6>
        <el-input style="width:50%;" v-model="info.fphm" size="small"></el-input>
      </div>
      <div class="top_input2 clearfix fr">
        <h6>开票日期</h6>
        <el-date-picker type="date" v-model="info.invoiceDate" placeholder="选择日期时间" size="small"></el-date-picker>
      </div>
    </div>
  </div>

  <div class="content invoice" :class="{'special': invoiceType !='1' && invoiceType !='4', 'red': invoiceType !='1' && invoiceType !='4'}">
    <!-- 购方 -->
    <el-row :span="24" class="row_box2">
      <el-col :xs="1" :sm="1" :lg="1">
        <div class="grid-content height_font">
          <p>购方单位</p>
        </div>
      </el-col>
      <el-col :xs="3" :sm="3" :lg="3">
        <div class="grid-content">
          <ul class="list_info weight">
            <li>名称<span class="star">*</span></li>
            <li>纳税人识别号</li>
            <li>地址、电话</li>
            <li>开户行及账号</li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="7" :sm="7" :lg="7">
        <div class="grid-content">
          <ul class="list_info">
            <li>
              <div style="text-align: left;line-height: 24px;">
                <corp-list :borderNone="true" v-model:corpId="info.corpId" type="1" :comId="comId"></corp-list>
              </div>
            </li>
            <li class="border_none">
              <el-input class="boxShadowNone" v-model="info.taxNo"></el-input>
            </li>
            <li class="border_none">
              <el-input class="boxShadowNone" v-model="info.addressTel"></el-input>
            </li>
            <li class="border_none">
              <el-input class="boxShadowNone" v-model="info.bankNameNo"></el-input>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="3" :sm="3" :lg="3">
        <div class="grid-content">
          <ul class="list_info">
            <li>
              <span >开票形式</span>
            </li>
            <li>
              <span >征收方式</span>
            </li>
            <li>印花税</li>
            <li>外币核算</li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="6" :sm="6" :lg="6">
        <div class="grid-content">
          <ul class="list_info">
            <li>
              <div>
                <el-radio-group v-model="info.openMethod">
                  <el-radio :label="1">自开</el-radio>
                  <el-radio :label="2">税局代开</el-radio>
                </el-radio-group>
              </div>
            </li>
            <li>
              <div>
                <el-radio-group v-model="info.zsfs">
                  <el-radio  :label="0">正常</el-radio>
                  <!-- <el-radio  :label="2">即征即退</el-radio>
                  <el-radio  :label="3">异地自开</el-radio> -->
                  <el-radio  :label="1">差额征税</el-radio>
                </el-radio-group>
              </div>
            </li>
            <li>  
              <div>
                <el-radio-group v-model="info.stampStatus">
                  <el-radio :label="0">缴纳</el-radio>
                  <el-radio :label="1">不缴</el-radio>
                </el-radio-group>   
              </div>   
            </li>
            <li class="border_none">
              <span style="float: left;width: 120px;">
                <el-select filterable v-model="info.wb" placeholder="请选择" size="small" class="boxShadowNone">
                  <template v-for="(item,index) in wbLists">
                    <el-option
                      v-if="item.label != '人民币'"
                      :label="item.label"
                      :value="item.value"
                      :key="index"
                    ></el-option>
                  </template>
                </el-select>
              </span>
              <span v-if="info.wb" style="float: left;width: 60px;margin-left:10px;">
                <input
                  style="border:1px solid #ccc;height:26px;font-size:10px"
                  placeholder="外币金额"
                  type="text"
                  v-model.number="info.wbAmount"
                  class="boxShadowNone"
                />
              </span>
              <span v-if="info.wb" style="float: left;width: 50px;margin-left:10px;">{{rate_wb}}</span>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="4" :sm="4" :lg="4">
        <div class="input_box">
          <div class="top_font"></div>
          <div class="top_font">

            <el-input v-if="info.zsfs == 1" placeholder="差额征税额" v-model="info.cezsAmount"></el-input>
          </div>
          <div class="top_font item_box" style="border-top:0;height:39px">
            <!-- <input placeholder="转出金额" style="padding-left:15px"> -->
          </div>
          <div class="top_font item_box" style="border-top:0;border-bottom:none">
            <!-- <input placeholder="应退税额" style="padding-left:15px"> -->
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 购方 end -->

    <!-- 中间栏 -->
    <div :key="index" class="item_box item_list">
      <el-row :span="24">
        <el-col :xs="3" :sm="3" :lg="3">
          <div class="grid-content bg-purple">业务类型
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="3" :sm="3" :lg="3">
          <div class="grid-content bg-purple">货物或应税劳务、服务
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">商品编码
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">税目
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">规格型号</div>
        </el-col>
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="grid-content bg-purple">单位</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">数量</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">单价</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">金额
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="grid-content bg-purple">税率
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple">税额
            <span class='star'>*</span>
          </div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
        <div class="grid-content bg-purple">价税合计
            <span class='star'>*</span>
        </div>
        </el-col>
      </el-row>
    </div>
    <!-- 中间栏 end -->
    <!-- items -->
    <div v-for="(item, index) in info.items" :key="index" class="item_box">
      <div class="close" @click="handleDelItem(info.items, index)">
        <i class="iconfont icon-shanchu"></i>
      </div>

      <div class="add" @click="handleAddItem(info.items, index)">
        <i class="iconfont icon-jiahao"></i>
      </div>

      <el-row :span="24">
        <!-- 业务类型 (科目)-->
        <el-col :xs="3" :sm="3" :lg="3">
          <div class="grid-content">
            <subject-list :borderNone="true" width="0" v-model:subjectId="item.subjectId" :comId="comId" v-model:fzhsItemId="item.fzhsId" :codes="$findCode(['code1606','code5001','code5051','code5052','code5053','code5301','code4901'],period)"></subject-list>
          </div>
        </el-col>
        <!-- 货物或应税劳务名称 -->
        <el-col :xs="3" :sm="3" :lg="3">
          <div class="grid-content" v-if="!info.id || !item.itemId || !item.itemName">
            <inventory-list :comId="comId" :borderNone="true" v-model:inventoryId="item.itemId" v-model:inventoryType="item.itemType" v-model:spec="item.spec" v-model:unit="item.unit" :type="true"></inventory-list>
          </div>
          <div class="grid-content" v-else-if="!item.showStatus && info.id" @click="item.showStatus = true" style="cursor: pointer;">
             {{ item.itemName }}
          </div>
          <div class="grid-content" v-else-if="item.showStatus && info.id">
            <inventory-list :comId="comId" :borderNone="true" v-model:inventoryId="item.itemId" v-model:inventoryType="item.itemType" v-model:spec="item.spec" v-model:unit="item.unit" :type="true"></inventory-list>
          </div>
         
        </el-col>
        <!-- 商品编码 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content">
            <ssflbm class="boxShadowNone" :borderNone="true" v-model:ssflbm="item.ssflbm"></ssflbm>
          </div>
        </el-col>
        <!-- 税目 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content">
            <shuimu class="boxShadowNone" :borderNone="true" v-model:taxId="item.taxId" v-model:taxRate="item.taxRate" @success="changeAmount(item)"></shuimu>
          </div>
        </el-col>
        <!-- 规格型号 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content border_none">
            <el-input  class="boxShadowNone" v-model="item.spec" type="text"></el-input>
          </div>
        </el-col>
        <!-- 单位 -->
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="grid-content border_none">
            <el-input class="boxShadowNone" v-model="item.unit" type="text"></el-input>
          </div>
        </el-col>
        <!-- 数量 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content border_none">
            <el-input class="boxShadowNone" v-model="item.count" type="text" @change="changeCount(item)"></el-input>
          </div>
        </el-col>
        <!-- 单价 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content border_none">
            <el-input class="boxShadowNone" v-model="item.price" type="text" @change="changePrice(item)"></el-input>
          </div>
        </el-col>
        <!-- 金额 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="border_none">
            <el-input class="boxShadowNone" v-model="item.amount" type="text" @change="changeAmount(item)"></el-input>
          </div>
        </el-col>
        <!-- 税率 -->
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="border_none">
            <el-input class="boxShadowNone sl_style" disabled v-model="item.taxRate" type="text"></el-input>
          </div>
        </el-col>
        <!-- 税额 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="border_none">
            <el-input class="boxShadowNone" v-model="item.tax" type="text"></el-input>
          </div>
        </el-col>
        <!-- 价税合计 -->
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="border_none">
            <el-input class="boxShadowNone" v-model="item.total" type="text" @change="changeTotal(item)"></el-input>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 共计 -->
    <div class="total">
      <el-row :span="24">
        <el-col :xs="17" :sm="17" :lg="17">
          <div class="grid-content bg-purple">合计</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple" style="text-align:left;padding:0 15px">{{sumAmount}}</div>
        </el-col>
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="grid-content bg-purple"></div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2">
          <div class="grid-content bg-purple" style="text-align:left">{{sumTax}}</div>
        </el-col>
        <el-col :xs="2" :sm="2" :lg="2" style="height:100%;;text-align:center;">
          <div class="grid-content bg-purple" style="text-align:left;padding:0 15px">{{sumTotal}}</div>
        </el-col>
      </el-row>
    </div>
    <!-- 共计-end -->
    <!-- 销方 -->
    <!-- <el-row :span="24" class="row_box2">
      <el-col :xs="1" :sm="1" :lg="1">
        <div class="grid-content height_font">
          <p>销方单位</p>
        </div>
      </el-col>
      <el-col :xs="3" :sm="3" :lg="3">
        <div class="grid-content">
          <ul class="list_info weight">
            <li>名称<span class="star">*</span></li>
            <li>纳税人识别号</li>
            <li>地址、电话</li>
            <li>开户行及账号</li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="7" :sm="7" :lg="7">
        <div class="grid-content">
          <ul class="list_info border_none">
            <li></li>
            <li><input type="text" ></li>
            <li><input type="text" ></li>
            <li><input type="text" ></li>
          </ul>
        </div>
      </el-col>
      <el-col :xs="1" :sm="1" :lg="1">
        <div class="grid-content height_font">
          <p>备注</p>
        </div>
      </el-col>
      <el-col :xs="12" :sm="12" :lg="12">
        <div class="grid-content">
        </div>
      </el-col>
    </el-row> -->
    <!-- 销方 end -->

  </div>
  <div class="bottom_num">
    发票张数
    <el-input v-model="info.invoiceCount" style="width:100px" size="small" />
    <div v-if="!comId">
      <el-button size="small" style="float:left" @click="changeInvoicePre(info)">上一张</el-button>
      <el-button size="small" style="float:left" @click="changeInvoiceNext(info)">下一张</el-button>
    </div>
    
  </div>
  <div style="float:right"><el-button :loading="loading" size="small" @click="saveInvoice" type="success">保存</el-button></div>
  <div style="float:right"><el-button :loading="loading" size="small" @click="saveInvoice('aa')" type="success" style="margin-right:10px">保存且继续新增</el-button></div>


</template>

<script>
import ssflbm from '@/components/ssflbm/ssflbm.vue'
import shuimu from '@/components/ssflbm/shuimu.vue'
import { wbList, saveOutInvoice ,invoiceNextInfo } from '@/api/invoice.js'
import { getPeriodTime } from "@/utils/fun";

export default {
  components: {
    ssflbm,
    shuimu,
  },
  props: {
    invoiceType: {//发票类型 1专票 2普票 3 无票 4电专 5电普'  
      type: String,
      default: "1"
    },
    comId: {
      type: Number,
      default: 0
    },
    period: {//账期
      type: String,
      default: ""
    },
  },
  data() {
    return {
      loading: false,
      wbLists: [],
      info: {
        invoiceCount: 1,
        invoiceDate: getPeriodTime(this.period),
        openMethod: 1,
        zsfs: 0,
        stampStatus: 0,
        items: [{},{}],
      },
      sumAmount:0
    }
  },
  computed: {
    //金额合计
    // sumAmount() {
    //   let count = 0;
    //   this.info.items.map(v => {
    //     if(v.count && v.count != 0 && v.price){
    //       count += Number((v.count *1 * v.price *1).toFixed(2))
    //     }else if( v.price && (v.count == 0 || !v.count)){
    //       count += Number((v.price*1).toFixed(2))
    //     }else if(v.amount){
    //       count += Number((v.amount*1).toFixed(2))
    //     }
    //   });
    //   return Number(count.toFixed(2));
    // },
    // 税额合计
    sumTax() {
      let count = 0;
      this.info.items.map(v => {
        if (v.tax) {
          count += Number(v.tax);
        }
      });
      return Number(count.toFixed(2));
    },
    //  价税总计
    sumTotal() {
      let count = 0;
      this.info.items.map(v => {
        if (v.total) {
          count += Number(v.total);
        }
      });
      return Number(count.toFixed(2));
    },
    rate_wb() {
      let count = 0;
      this.info.items.map(v => {
        if (v.total) {
          count += Number(v.total);
        }
      });
      if (this.info.wbAmount) {
        return Number((count / this.info.wbAmount).toFixed(4));
      } else {
        return 0;
      }
    },
  },
  created () {
    this.getWbList()
  },
  methods: {
    changeInput(e){
      //console.log(e);
    },
    getWbList(){
      wbList({}).then(res => {
        this.wbLists = res.data.data.list;
      });
    },
    init(info){
      this.sumAmount = 0
      if(info){
        this.info = info;
        this.info.items.map(v => {
          if (v.amount && v.count && v.count != 0) {
            v.price = Number((v.amount/v.count).toFixed(2))
          }else if(v.count == 0 || !v.count && v.amount){
            v.price = Number((v.amount).toFixed(2))
          }
          this.sumAmount += Number((v.amount).toFixed(2))
          this.sumAmount = (this.sumAmount).toFixed(2)*1
        });
        // this.invoiceType = info.invoiceType
      }else{
        this.info = {
          invoiceCount: 1,
          invoiceDate: getPeriodTime(this.period),
          openMethod: 1,
          zsfs: 0,
          stampStatus: 0,
          items: [{},{}],
        }
      }
    },
    saveInvoice(param){
      this.info.invoiceType = this.invoiceType;
      this.info.sumTax = this.sumTax;
      this.info.sumTotal = this.sumTotal;
      this.info.sumAmount = this.sumAmount;
      this.info.rate = this.rate_wb;
      this.info.invoiceCount = Number(this.info.invoiceCount)
      this.info.cezsAmount = Number(this.info.cezsAmount)

      let items = this.info.items.filter(bb => {
        if (!bb.subjectId && !bb.itemId) {
          return false;
        } else {
          return true;
        }
      });
      if(items.length == 0){
        this.$message.error("至少一条发票信息");
        return
      }

      this.info.items = items;

      //判断
      if (!this.info.corpId) {
        this.$message.error("绑定进方单位");
        return;
      }
      let flag = true;
      this.info.items.map(item => {
        item.amount = item.amount?Number(item.amount):0
        item.tax = item.tax?Number(item.tax):0
        item.total = item.total?Number(item.total):0
        item.count = item.count?Number(item.count):0
        item.price = item.price?Number(item.price):0
        if (!item.subjectId) {
          this.$message.error("请完善业务类型");
          flag = false;
          return;
        }
        if (!item.itemId) {
          this.$message.error("请完善开票项目");
          flag = false;
          return;
        }
        if (!item.ssflbm) {
          this.$message.error("请完善商品编码");
          flag = false;
          return;
        }
        if (!item.taxId) {
          this.$message.error("请完善税目");
          flag = false;

          return;
        }
        if (!item.amount) {
          this.$message.error("请完善金额");
          flag = false;

          return;
        }
        if (!item.total) {
          this.$message.error("请完善价税合计");
          flag = false;
          return;
        }
      });
      if (!flag) {
        return;
      }
      if(this.comId && this.period){
        this.info.comId = this.comId
        this.info.period = this.period
      }
      this.loading = true
      saveOutInvoice(this.info).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          if(this.comId && this.period){
            this.$emit("success")
          }else{
            if(this.info.id){
              this.$bus.emit('invioceOutUpdate')
              this.$bus.emit('voucherUpdate')
              this.$bus.emit('invioceOutUpdate', this.info)
            }else{
              this.$bus.emit('invioceOutUpdate', this.info)
            }
          }
          if(param == 'aa'){
            this.sumAmount = 0
            this.info = {
              invoiceCount: 1,
              invoiceDate: getPeriodTime(this.period),
              openMethod: 1,
              zsfs: 0,
              stampStatus: 0,
              items: [{},{}],
            }
            return
          }else{
            this.info.id = res.data.data.info.id
          }
        }
      })

    },


    handleAddItem(item, index) {
      item.splice(index + 1, 0, {
        subjectId: "", //业务类型
        taxId: "", //税目
        spec: "", // 规格型号
        unit: "", // 单位
        count: "", // 数量
        amount: "", // 金额
        taxRate: "", // 税率
        tax: "", // 税额
        total: "" // 价税合计
      });
    },
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
      this.computedSumAmount()
    },
     //输入数量
     changeCount(row){
      if(row.count && row.count != 0){
        if(row.price){
          row.amount =  Number((row.count *1 * row.price *1).toFixed(2))
        }
        if(row.amount){
          row.price = Number((row.amount *1 / row.count *1).toFixed(2))
        }
      }else if(row.count == 0 || !row.count){
        if(row.price){
          row.amount =  Number((row.price *1).toFixed(2))
        }
        if(row.amount){
          row.price = Number((row.amount).toFixed(2))
        }
      }
      let amount = row.amount; //金额
      let tax = 0; //税额
      let total = 0; //价税合计
      if (row.taxRate || row.taxRate == 0) {
        if(!amount){
          row.total = 0
          row.tax = 0
        }else{
          tax = Number(amount).toFixed(2) * Number(row.taxRate);
          row.tax = Number(tax.toFixed(2));
          total = Number(amount).toFixed(2) *1 + Number(tax).toFixed(2) *1;
          row.total = Number(total.toFixed(2));
        }  
      }
      this.computedSumAmount()
    },
    //输入价格
    changePrice(row){
      if(row.count && row.count != 0){
        if(row.price){
          row.amount =  Number((row.count *1 * row.price *1).toFixed(2))
        }else if(!row.price || row.price == 0){
          row.amount =  0
        }
      }else if(row.count == 0 || !row.count){
        if(row.price){
          row.amount =  Number((row.price *1).toFixed(2))
        }else if(!row.price || row.price == 0){
          row.amount =  0
        }
      }
      let amount = row.amount; //金额
      let tax = 0; //税额
      let total = 0; //价税合计
      if (row.taxRate || row.taxRate == 0) {
        tax = Number(amount).toFixed(2) * Number(row.taxRate);
        row.tax = Number(tax.toFixed(2));
        total = Number(amount).toFixed(2) *1 + Number(tax).toFixed(2) *1;
        row.total = Number(total.toFixed(2));
      }
      this.computedSumAmount()
    },
    //输入金额
    changeAmount(row) {
      if(row.count && row.count != 0 ){
        row.price = Number((row.amount *1 / row.count *1).toFixed(2))
      }else if(row.count == 0 || !row.count){
        row.price = row.amount ? Number((row.amount*1).toFixed(2)) : 0
      }
      let amount = row.amount; //金额
      let tax = 0; //税额
      let total = 0; //价税合计
      if (row.taxRate || row.taxRate == 0) {
        if(!amount){
          row.total = 0
          row.tax = 0
        }else{
          tax = Number(amount).toFixed(2) * Number(row.taxRate);
          row.tax = Number(tax.toFixed(2));
          total = Number(amount).toFixed(2) *1 + Number(tax).toFixed(2) *1;
          row.total = Number(total.toFixed(2));
        }  
      }
      this.computedSumAmount()
    },
    computedSumAmount(){
      let count = 0;
      this.info.items.map(v => {
        if(v.amount){
          count += Number((v.amount*1).toFixed(2))
        }
          
        })
      // if(this.info.id){
      
      // }else{
      //   this.info.items.map(v => {
      //   if(v.count && v.count != 0 && v.price){
      //     count += Number((v.count *1 * v.price *1).toFixed(2))
      //   }else if( v.price && (v.count == 0 || !v.count)){
      //     count += Number((v.price*1).toFixed(2))
      //   }else if(v.amount){
      //     count += Number((v.amount*1).toFixed(2))
      //   }
      //  })
      // }
      this.sumAmount = count
      this.sumAmount = (this.sumAmount).toFixed(2)*1
    },
    // 输入价税合计
    changeTotal(row){
      if(this.invoiceType == '3'){
        let total = row.total
        let amount = 0; //金额
        let tax = 0; //税额
        //console.log(row.taxRate);
        if (row.taxRate || row.taxRate == 0) {
          amount = total/(1+row.taxRate)
          row.amount = Number(amount.toFixed(2))
          tax = total - amount
          row.tax = Number(tax.toFixed(2))
        }
      }
    },

     // 上一张
     changeInvoicePre(content){
      let param = {
        id:content.id,
        type:'pre',
        from:'out'
      }
      invoiceNextInfo(param).then(res => {
        if(res.data.data.info.id == 0){
          this.$qzfMessage("已经是第一张了", 1)
          return
        }
        this.$store.dispatch('commons/setParam', {addOutInvoiceId: res.data.data.info.id})
        this.$store.dispatch('tagsView/delCachedViewByName', "addOutput")
        this.$router.push({
          path: "/bookkeeps/addOutput",
          name: "addOutput"
        });
        this.$emit('success',res.data.data.info.invoiceType)
        this.init(res.data.data.info)
      })
    },
    changeInvoiceNext(content){
      let param = {
        id:content.id,
        type:'next',
        from:'out'
      }
      invoiceNextInfo(param).then(res => {
        if(res.data.data.info.id == 0){
          this.$qzfMessage("已经是最后一张了", 1)
          return
        }
        this.$store.dispatch('commons/setParam', {addOutInvoiceId: res.data.data.info.id})
        this.$store.dispatch('tagsView/delCachedViewByName', "addOutput")
        this.$router.push({
          path: "/bookkeeps/addOutput",
          name: "addOutput"
        });
        this.$emit('success',res.data.data.info.invoiceType)
        this.init(res.data.data.info)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
  }
}
  .top_title {
    width: 100%;
    margin: 0 auto;
    h5 {
      font-size: 30px;
      color: #333;
      text-align: center;
      font-weight: 500;
      margin-bottom: 36px;
    }
  }
  .top_input {
    width: 30%;
    h6 {
      float: left;
      width: 60px;
      font-size: 12px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
    }
  }
  .top_input2 {
    //width: 30%;
    margin: 0 auto;
    position: relative;
    h6 {
      float: left;
      width: 60px;
      font-size: 12px;
      color: #333;
      font-weight: normal;
      line-height: 28px;
      margin-right: 10px;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .content {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #008000;
    margin-top: 10px;
    border-right: none;
  }
  .grid-content {
    position: relative;
    min-height: 38px;
    font-size: 12px;
    color: #666;
    line-height: 34px;
    text-align: center;
  }
  .row_box2 {
    width: 100%;
    height: 154px;
  }
  .height_font {
    width: 100%;
    height: 152px;
    margin: 0 auto;
    text-align: center;
    p {
      width: 20px;
      margin: 0 auto;
      height: 100%;
      line-height: 28px;
      padding-top: 22px;
    }
  }
  .list_info {
    line-height: 24px;
    &.red li {
      border-bottom: 1px solid #d56400 !important;
    }
    li {
      position: relative;
      line-height: 38px;
      height: 37px;
      font-size: 12px;
      color: #333;
      border-bottom: 1px solid #008000;

      &:last-child {
        border-bottom: none;
      }
      input {
        width: 100%;
        margin: 0 auto;
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        color: #333;
        background: none;
        border: none;
        padding: 0 5px;
      }
    }
  }
  .weight {
    font-weight: 700;
  }
  .weight {
    li {
      font-weight: 600 !important;
    }
  }
  .star {
    color: #f15a24;
    height: 12px;
    font-size: 18px;
    display: inline-block;
    z-index: 999;
  }
  .input_box {
    width: 100%;
    height: 152px;
    font-size: 12px;
    color: #666;
    // line-height: 38px;
    text-align: center;
  }
  .top_font {
    border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 38px;
    font-size: 12px;
    color: #333;
  }
  .item_box {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .close {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      right: -32px;
      top: 9px;
      font-size: 20px;
      color: #ff1818;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .add {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      left: -28px;
      top: 9px;
      font-size: 16px;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    input {
      width: 100%;
      margin: 0 auto;
      height: 38px;
      line-height: 38px;
      font-size: 12px;
      color: #333;
      background: none;
      border: none;
      padding: 0 5px;
    }
  }
  .item_list {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .add {
      position: absolute;
      right: -38px;
      top: 10px;
      width: 1.8em;
      height: 1.8em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .bg-purple {
      line-height: 38px;
      height: 38px;
      font-size: 12px;
      color: #333;
      border-bottom: 1px solid #008000;
      // position: relative;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .bg-purple {
    text-align: center;
  }
  .bottom_num {
    width: 20%;
    margin-top: 10px;
    font-size: 12px;
    color: #333;
    font-weight: normal;
    line-height: 36px;
  }
  .total {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    // border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 38px;
    font-size: 12px;
    color: #333;
  }
 .el-row {
  width: 100%;
  }
 .el-col {
  border-right: 1px solid #008000;
  }
  .special h5 {
    color: #d56400;
    border-bottom: 4px double #d56400;
    span {
      border-bottom: 16px solid #d56400;
    }
  }
  .special .el-col {
    border-right: 1px solid #d56400;
  }
  .special .content {
    border: 1px solid #d56400;
    border-right: none;
  }
  .special .list_info li {
    border-bottom: 1px solid #d56400;
    &:last-child {
      border-bottom: none;
    }
  }
  .special .textarea {
    border-bottom: 1px solid #d56400;
  }
  .special .top_font {
    border-bottom: 1px solid #d56400;
  }
  .special .item_list {
    border-top: 1px solid #d56400;
  }
  .special .item_box {
    border-top: 1px solid #d56400;
  }
  .special .total {
    border-top: 1px solid #f15a24;
  }
  .red {
    border-color: #d56400 !important;
  }
</style>


<style>
.invoice-radio .el-radio__label{
  padding-left: 0 !important;
}
.invoice-radio .el-radio{
  margin-right: 0 !important;
}
.invoice .el-input__inner{
  height: 36px;
}
.sl_style .el-input__wrapper{
  padding: 1px 5px !important;
}
</style>